<template>
  <main>
    <section class="section">
      <div class="container">
        <div class="products-wrap">
          <aside class="filter">
            <div class="filter__block">
              <div class="filter__title">Category</div>
              <multiselect
                :value="category"
                :options="categories"
                track-by="id"
                label="name"
                :searchable="false"
                :show-labels="false"
                class="filter__select"
                @select="filterCategory"
              >
                <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                  <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                </span>
              </multiselect>
            </div>

            <div class="filter__block">
              <div v-if="subCats.length || subCat" class="filter__title">SubCategory</div>
              <multiselect
                v-if="subCats.length || subCat"
                :value="subCat"
                :options="subCats"
                track-by="id"
                label="name"
                :searchable="false"
                :show-labels="false"
                class="filter__select"
                @select="filterSubCat"
              >
                <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                  <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                </span>
              </multiselect>
            </div>

            <div class="filter__block">
              <div v-if="subSubCats.length || subSubCat" class="filter__title">Sub-subCategory</div>
              <multiselect
                v-if="subSubCats.length || subSubCat"
                :value="subSubCat"
                :options="subSubCats"
                track-by="id"
                label="name"
                :searchable="false"
                :show-labels="false"
                class="filter__select"
                @select="filterSubSubCat"
              >
                <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                  <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                </span>
              </multiselect>
            </div>

            <div class="filter__block">
              <div v-if="filterParams.length || filterParam" class="filter__title">Filters</div>
              <multiselect
                v-if="filterParams.length || filterParam"
                :value="filterParam"
                :options="filterParams"
                track-by="id"
                label="name"
                :searchable="false"
                :show-labels="false"
                class="filter__select"
                @select="changeFilterParam"
              >
                <span slot="caret" slot-scope="{toggle}" class="arrow" @mousedown.prevent.stop="toggle">
                  <div class="icon"><i class="ri-arrow-drop-down-line"></i></div>
                </span>
              </multiselect>
            </div>
            <div v-if="category != null" class="btn-wrap">
              <div class="btn btn--small" @click="reset">Reset Filters</div>
            </div>
          </aside>
          <div class="results-wrap">
            <div class="counter">
              <div class="counter__title">Results</div>
              <div class="counter__num">{{ products.length }}</div>
            </div>
            <ul class="results">
              <li v-for="item in sortedProducts" :key="item.productId" class="results__item">
                <router-link :to="/products/ + item.productId" class="results__router">
                  <div class="results__img-wrap">
                    <img v-if="item.photos && item.photos.length" :src="item.photos[0].url" class="results__img" />
                    <img v-else src="@/assets/images/products.png" alt="product image" class="results__img" />
                  </div>

                  <div class="results__info">
                    <div v-if="item.isBestSeller">
                      <div class="results__badge"><i class="ri-award-fill"></i>Bestseller</div>
                    </div>

                    <h4 class="results__title">{{ item.product }}</h4>
                    <p class="results__desc"></p>
                    <router-link :to="/products/ + item.productId" class="link">Learn more</router-link>
                  </div>
                </router-link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>
<script>
import {mapActions, mapState, mapGetters, mapMutations} from 'vuex'
import Multiselect from 'vue-multiselect'

export default {
  components: {
    Multiselect
  },
  data() {
    return {
      category: null,
      subCats: [],
      subCat: null,
      subSubCat: null,
      subSubCats: [],
      filterParams: [],
      filterParam: null
    }
  },
  computed: {
    ...mapState({
      products: s => s.products.products,
      stateFilterCat: s => s.products.filterCat,
      stateFilterSubCat: s => s.products.filterSubCat,
      stateFilterSubSubCat: s => s.products.filterSubSubCat,
      stateFilterParam: s => s.products.filterParam
    }),
    ...mapGetters({
      categories: 'main/categories'
    }),
    sortedProducts() {
      let products = this.products
      return products.sort((x, y) => y.isBestSeller - x.isBestSeller)
    }
  },
  watch: {
    categories(cats) {
      this.category = cats.find(c => c.name === 'Filler Metals')
      this.subCats = this.category.items
    }
  },

  async created() {
    window.scrollTo(0, 0)
    if (this.$route.params.name) this.selectCat()
    if (this.stateFilterCat) {
      this.category = this.categories.find(c => c.name === this.stateFilterCat)
      this.subCats = this.category.items
    } else {
      this.setFilterCat('Filler Metals')
      if (this.categories.length) {
        this.category = this.categories.find(c => c.name === 'Filler Metals')
        this.subCats = this.category.items
      }
    }
    if (this.stateFilterSubCat) {
      this.subCat = this.category.items.find(c => c.name === this.stateFilterSubCat)
      this.subSubCats = this.subCat.items
    }
    if (this.stateFilterSubSubCat) {
      this.subSubCat = this.subCat.items.find(c => c.name === this.stateFilterSubSubCat)
      this.filterParams = this.subSubCat.items
    }
    if (this.stateFilterParam) {
      this.filterParam = this.subSubCat.items.find(c => c.name === this.stateFilterParam)
    }

    await this.fetch()
  },

  methods: {
    ...mapActions({
      fetch: 'products/fetch'
    }),
    ...mapMutations({
      setFilterCat: 'products/setFilterCat',
      setFilterSubCat: 'products/setFilterSubCat',
      setFilterSubSubCat: 'products/setFilterSubSubCat',
      setFilterParam: 'products/setFilterParam'
    }),
    selectCat() {
      this.category = this.categories.find(c => c.name === this.$route.params.name)
      this.filterCategory(this.category)
    },
    async filterCategory(cat) {
      this.category = cat
      this.setFilterCat(cat.name)
      this.setFilterSubCat(null)
      this.setFilterSubSubCat(null)
      this.setFilterParam(null)
      this.subCat = this.subSubCat = this.filterParam = null
      this.subSubCats = this.filterParams = []
      this.subCats = cat.items
      await this.fetch()
    },
    async filterSubCat(cat) {
      this.subCat = cat
      this.setFilterSubCat(cat.name)
      this.setFilterSubSubCat(null)
      this.setFilterParam(null)
      this.subSubCat = this.filterParam = null
      this.filterParams = []
      this.subSubCats = cat.items
      await this.fetch()
    },
    async filterSubSubCat(cat) {
      this.subSubCat = cat
      this.setFilterSubSubCat(cat.name)
      this.setFilterParam(null)
      this.filterParam = null
      this.filterParams = cat.items
      await this.fetch()
    },
    async changeFilterParam(param) {
      this.filterParam = param
      this.setFilterParam(param.name)
      await this.fetch()
    },
    async reset() {
      this.category = this.subCat = this.subSubCat = this.filterParam = null
      this.subCats = this.subSubCats = this.filterParams = []
      this.setFilterCat(null)
      this.setFilterSubCat(null)
      this.setFilterSubSubCat(null)
      this.setFilterParam(null)
      await this.fetch()
    }
  },
  metaInfo: {
    title: 'Products | Flesherton Welding Supply',
    meta: [
      {
        name: 'description',
        content: 'We offer the full range of ESAB products and maintain a high level of stock of key welding supplies.'
      }
    ]
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss" scoped>
@import 'style';
</style>
